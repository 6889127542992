import { useContext } from 'react';
import styles from './Thanks.module.scss';
import LogoIcon from '../Icons/LogoIcon';
import ButtonError from '../UI/ButtonError';
import ArrowIcon from '../Icons/ArrowIcon';
import { GlobalContext } from '../../context/GlobalContext';
import skilliumLogo from '../../assets/images/skillium-logo.png';

const Thanks = ({ type, afterCallNow, onCallMeNow, onContinue }) => {
  const { translate } = useContext(GlobalContext);

  return (
    <div className={styles.thankYou}>
      <div className={styles.thankYou__logo}>
        <LogoIcon />
      </div>
      <div className={`${styles.thankYou__info} ${type === 1 && styles.thankYou__notSlots}`}>
        <img src={skilliumLogo} alt="Logo" className={styles.thankYou__info__skilliumLogo} />
        <h2>Thanks!</h2>
        <p>Registration confirmed</p>
        {afterCallNow && <span>We will call you in 5 minutes.</span>}
        {onCallMeNow && (
          <>
            <span>Our manager will contact you at the specified phone number or email.</span>
            <div className={styles.thankYou__info__btn}>
              <ButtonError onClick={onCallMeNow}>
                <>
                  Call me now <ArrowIcon />
                </>
              </ButtonError>
            </div>
          </>
        )}
        {!afterCallNow && onContinue && (
          <>
            <span>We will get in touch with you soon.</span>
            {type === 1 && <span>{translate.DESCRIPTION_IN_THANKS_PAGE}</span>}
            <div className={styles.thankYou__info__btn}>
              <ButtonError onClick={onContinue}>
                <>
                  Continue <ArrowIcon />
                </>
              </ButtonError>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Thanks;
