import { useState, useEffect, useContext } from 'react';
import { GlobalContext } from '../../context/GlobalContext';
import Thanks from '../../components/Thanks';

const ThankYou = () => {
  const [type, setType] = useState(1);
  const [redirectUri, setRedirectUri] = useState(null);

  const { environmentConfig } = useContext(GlobalContext);

  const params = new URL(document.location).searchParams;

  const handlerClickBtn = () => {
    if (redirectUri) {
      window.location.href = redirectUri;
    } else if (process.env.REACT_APP_DESIGN === 'Skillium') {
      window.location.href = 'https://skillium.org/';
    } else {
      window.location.href = environmentConfig.URL_ABOUT_EVLTR;
    }
  };

  const afterCallNowClick = params.get('from') === 'call-now';

  useEffect(() => {
    if (params.get('redirectUri')) {
      let url;
      url = decodeURIComponent(params.get('redirectUri'));
      if (params.get('theme')) {
        url += `&theme=${params.get('theme')}`;
      }
      setRedirectUri(url);
    } else if (!afterCallNowClick) {
      setType(2);
    }
  }, [params, afterCallNowClick]);

  return <Thanks type={type} afterCallNowClick={afterCallNowClick} onContinue={handlerClickBtn} />;
};

export default ThankYou;
